// vendors
import React from 'react';
import { hideVisually } from 'polished';

// utils
import { lessThan } from '@utils/mediaQuery';

// images
import IconArrow from '@images/IconArrow';

// styles
import { h1Style, linkStyle, unstyledLinkStyle } from '@styles/global';
import {
  Stack,
  List,
  Line,
  SubTitle,
  AddressSection,
  DepartmentSection,
  CTASection,
} from '@views/ContactPageView/DetailContainer/DetailContainer.styles';

// components
import Layout from '@components/Layout';
import SideBarLayout from '@components/SideBarLayout';
import Button from '@components/Button';

// views
import IconContainer from '@views/ContactPageView/IconContainer';
import DetailContainer from '@views/ContactPageView/DetailContainer';
import SEO from '@src/components/SEO';

const ContactUs = () => {
  const langLinks = [{ langKey: 'fr', href: '/nous-contacter' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang='en'
        langLinks={langLinks}
        title='Contact us'
        description='Permafil  are the experts in commercial and domestic storage spaces. Contact us for a quote or complete the order form and a member of our team will get in touch with you.'
      />

      <SideBarLayout>
        <IconContainer>
          <h1 css={h1Style}>Contact us</h1>
        </IconContainer>

        <DetailContainer>
          <AddressSection>
            <SubTitle css={hideVisually}>Our address</SubTitle>

            <p>
              <a
                itemProp='address'
                itemScope
                itemType='http://schema.org/PostalAddress'
                href='https://goo.gl/maps/W6zUZBLcL1F1h8id7'
                target='_blank'
                rel='noreferrer'
                css={unstyledLinkStyle}
              >
                <span itemProp='streetAddress'>225, rue Industrielle</span>
                ,&nbsp;
                <br />
                <span itemProp='addressLocality'>Sainte-Marguerite</span>
                ,&nbsp;
                <span itemProp='addressRegion'>Quebec</span>
                ,&nbsp;
                <br />
                <span itemProp='postalCode'>G0S 2X0</span>
              </a>
            </p>
          </AddressSection>

          <DepartmentSection itemProp='department'>
            <SubTitle itemProp='name'>Administration</SubTitle>

            <List>
              <Line>
                <span>Tel: </span>
                <a href='tel:+18004631434' itemProp='telephone' css={linkStyle}>
                  1-800-463-1434
                </a>
              </Line>

              <Line>
                <span>Fax: </span>
                <span itemProp='faxNumber'>418-624-3338</span>
              </Line>

              <Line>
                <span>Email: </span>
                <a
                  href='mailto:info@permafil.com'
                  itemProp='email'
                  css={linkStyle}
                >
                  info@permafil.com
                </a>
              </Line>
            </List>
          </DepartmentSection>

          <DepartmentSection itemProp='department'>
            <SubTitle itemProp='name'>Sales office</SubTitle>

            <List>
              <Line>
                <span>Tel: </span>
                <a href='tel:+18004631434' itemProp='telephone' css={linkStyle}>
                  1-800-463-1434
                </a>
              </Line>

              <Line>
                <span>Fax: </span>
                <span itemProp='faxNumber'>418-624-3338</span>
              </Line>

              <Line>
                <span>Email: </span>
                <a
                  href='mailto:ventes@permafil.com'
                  itemProp='email'
                  css={linkStyle}
                >
                  ventes@permafil.com
                </a>
              </Line>
            </List>
          </DepartmentSection>

          <CTASection>
            <SubTitle>Find our products</SubTitle>

            <Stack
              css={`
                display: flex;
                flex-direction: column;

                > * {
                  max-width: 495px;
                }
              `}
            >
              <Button
                primary
                outlined
                renderIcon={<IconArrow />}
                to='/en/consumers#retailers'
                tag='link'
              >
                View our distributors
              </Button>

              <Button
                primary
                renderIcon={<IconArrow />}
                to='/en/contact-form'
                tag='link'
                css={`
                  ${lessThan(480)} {
                    margin-left: -24px;
                    margin-right: -24px;
                  }
                `}
              >
                Order taking form
              </Button>

              <Button
                primary
                renderIcon={<IconArrow />}
                to='/en/contact-form'
                tag='link'
                css={`
                  ${lessThan(480)} {
                    margin-left: -24px;
                    margin-right: -24px;
                  }
                `}
              >
                Request information
              </Button>
            </Stack>
          </CTASection>
        </DetailContainer>
      </SideBarLayout>
    </Layout>
  );
};

ContactUs.propTypes = {};

export default ContactUs;
